<template>
  <div class="d-flex flex-column">
    <h1 class="text-h5 font-weight-bold mb-2">Autoavaliação</h1>
    <p class="pl-3">
      Após iniciar a avaliação o processo não poderá ser revertido.
    </p>
    <div class="d-flex flex-column align-center justify-center my-3">
      <v-img
        :src="require('@/assets/images/3d-characters/pose-f-39.png')"
        alt="imagem de iniciar avaliacao"
        contain
        height="12em"
        width="12em"
      />
      <v-btn
        :loading="loading"
        color="success"
        class="mt-8"
        @click="startEvaluation()"
        >Iniciar avaliação</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Evaluation",
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    startEvaluation() {
      this.loading = true;

      const data = {
        uuid: this.$route.params.uuid_form,
      };

      this.$store
        .dispatch("avdFormulariosColaboradores/startFormEvaluation", data)
        .then(() => {
          this.$emit("change-action", "form");
        })
        .catch(() => {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem:
              "Houve um erro em iniciar a avaliação. Por favor, tente novamente mais tarde ou entre em contato com o suporte.",
            cor: "red",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
